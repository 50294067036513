export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://nkk44tp503.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://lfq7aukgmi.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://00dzx3zjp8.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://h2fc0lnx7j.execute-api.eu-west-1.amazonaws.com/prod',
  ws: 'wss://6838chna5f.execute-api.eu-west-1.amazonaws.com/dev',
  export: 'https://6c7057fmed.execute-api.eu-west-1.amazonaws.com/dev/surveys',
};
